<template>
  <div class="c_prediction">
    <div class="prediction_top">
      <div class="title">{{title}}</div>
      <div class="desc">{{introduction}}</div>
    </div>
    <div class="prediction_bottom">
      <div class="left">
        <div class="tab-box">
          <div class="tab-nav">
            <div class="nav-item" v-for="(nav, index) in subjects" :key="index"
                 :class="[currentNav===nav.title?'nav-item-active':'',preNav === nav.title? 'nav-item-pre':'']"
                 @click="changeTab(nav,index)">
              <div><span>{{nav.title}}</span></div>
            </div>
          </div>
          <div class="tab-item-list">
            <template v-for="(subject,index) in subjects">
              <div class="tab-item" v-if="currentNav === subject.title" :key="index">
                <div class="con_part" v-for="(part, index) in subject.module" :key="index">
                  <div class="part">
                    <template v-if="part.type == 1">
                      <div class="part-desc">{{part.content}}</div>
                    </template>
                    <template v-else-if="part.type == 2">
                      <div class="part_type2">
                        <div class="part_top">
                          <div class="top_name">{{part.title}}</div>
                          <div class="top_total">总计{{part.labels.length}}个</div>
                        </div>
                        <p-swiper :swiper-data="part"></p-swiper>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="ads">
          <div class="ad" v-for="ad in ads" :key="ad.id">
            <div class="ad_title">{{ad.title}}</div>
            <div class="ad_pic">
              <a @click="turnPageTo(ad)">
                <img :src="ad.image_url" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qr-code" v-show="isShowQrcode">
      <div class="close-btn iconfont icon-papa-close-small" @click="closeQrCode"></div>
      <div class="title">立即咨询</div>
      <img class="qr-image" :src="qrCodeImageUrl" alt="">
      <div class="tip">微信扫码添加小助手，咨询课程</div>
<!--      <div class="reply">通过好友后请向小助手发送关键词 <b>{{detailData.keyword}}</b></div>-->
    </div>
  </div>
</template>

<script>
  import * as Api from '@/api/category/category'
  import PSwiper from './swiper.vue'
  import { resolveAddress } from '@/utils/utils'

  export default {
    name: 'CategoryPrediction',
    data () {
      return {
        title: '',
        introduction: '',
        subjects: [],
        currentNav: '',
        preNav: '',
        ads: [],
        isShowQrcode: false,
        qrCodeImageUrl: ''
      }
    },
    mounted () {
      this.getData()
      this.getAds()
    },
    methods: {
      getData () {
        Api.predictions(res => {
          this.title = res.title
          this.introduction = res.introduction
          this.subjects = res.subjects
          this.currentNav = res.subjects[0].title
          console.log(res)
        })
      },
      getAds () {
        let params = {
          position: 6
        }
        Api.predictionAds(params, res => {
          console.log(res)
          res.forEach(ele => {
            ele.address = resolveAddress(ele)
          })
          this.ads = res
        }, 'v10')
      },
      changeTab (item, index) {
        if (index > 0) {
          this.preNav = this.subjects[index - 1].title
        }
        this.currentNav = item.title
      },
      turnPageTo (item) {
        console.log(item)
        _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
        if (item.address.indexOf('http') > -1) {
          window.open(item.address)
        } else if (item.address.indexOf('qrCode') > -1) {
          this.qrCodeImageUrl = item.detail_image_url
          this.isShowQrcode = true
          this.$store.dispatch('setGlobalMask', {status: true})
        } else {
          window.location.href = item.address
        }
      },
      closeQrCode () {
        this.isShowQrcode = false
        this.$store.dispatch('setGlobalMask', {status: false})
      }
    },
    components: {
      PSwiper
    }
  }
</script>

<style lang="less" scoped>
  .c_prediction {
    width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
    user-select: none;

    .prediction_top {
      box-sizing: border-box;
      width: 1200px;
      height: 150px;
      background: url("./banner-bg.png") no-repeat;
      border-radius: 8px;
      margin-top: 20px;
      text-align: left;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 59px;

      & > .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      & > .desc {
        font-size: 20px;
      }
    }

    & > .prediction_bottom {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;

      & > .left {
        width: 900px;
        height: 100%;
        border-radius: 8px;
        background: #fff;

        & > .tab-box {
          & > .tab-nav {
            width: 100%;
            height: 80px;
            background: #ECF0EE;
            display: flex;

            & > .nav-item {
              height: 100%;
              width: 120px;
              line-height: 80px;
              cursor: pointer;
              background: #fff;
              font-size: 16px;

              & > div {
                width: 100%;
                height: 100%;
                background: #ECF0EE;
              }
            }

            .nav-item-pre {
              div {
                border-radius: 0 0 8px 0;
              }
            }

            .nav-item-active + .nav-item div {
              border-radius: 0 0 0 8px;
            }

            & > .nav-item-active {
              background: #fff;
              color: #333;
              font-weight: 500;
              font-size: 18px;
              background: #ECF0EE;

              & > div {
                border-radius: 8px 8px 0 0;
                background: #fff;

                & > span {
                  box-sizing: border-box;
                  display: inline-block;
                  position: relative;
                }

                & > span::after {
                  content: '';
                  width: 100%;
                  height: 6px;
                  background: @papaGreen;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  border-radius: 6px;
                }
              }
            }
          }

          & > .tab-item-list {
            width: 100%;
            box-sizing: border-box;
            padding: 40px;

            & > .tab-item {
              width: 100%;

              & > .con_part {
                width: 100%;
                box-sizing: border-box;
                border-bottom: 1px solid #e6e6e6;
                padding: 30px 0;

                & > .part {
                  .part-desc {
                    font-size: 18px;
                    color: #333;
                    text-align: left;
                  }

                  & > .part_type2 {
                    .slider {
                      position: relative;
                    }

                    & > .part_top {
                      display: flex;
                      width: 100%;
                      justify-content: space-between;

                      & > .top_name {
                        font-size: 20px;
                        color: #333;
                        font-weight: 600;
                      }

                      & > .top_total {
                        font-size: 14px;
                        color: #999;
                      }
                    }
                  }
                }
              }

              & > .con_part:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }

      & > .right {
        width: 280px;

        & > .ads {
          & > .ad {
            box-sizing: border-box;
            width: 100%;
            height: 222px;
            border-radius: 8px;
            margin-bottom: 20px;
            background: #fff;
            padding: 20px 20px 30px 20px;
            cursor: pointer;

            .ad_title {
              font-size: 20px;
              color: #333;
              font-weight: 600;
              margin-bottom: 20px;
              text-align: left;
            }

            img {
              width: 240px;
              height: 132px;
            }
          }
        }
      }
    }
    .qr-code {
      width: 380px;
      height: 300px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: fixed;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-bottom: 26px;
      }

      & > .tip {
        font-size: 18px;
        color: #333;
      }

      & > .reply {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
      }
    }

  }
</style>
