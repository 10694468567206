<template>
  <div class="slider">
    <div class="button-paginations">
      <div class="pagination-button" v-for="(item,buttonIndex) in swiperData.labels" :key="buttonIndex"
           @click="changeSlider(item, buttonIndex)"
           :class="currentSlideId === buttonIndex?'pagination-button-active':''">
        选项卡{{buttonIndex+1}}
      </div>
    </div>
    <swiper :options="swiperOption"
            ref="swiperrefs">
      <swiper-slide v-for="(slide, index) in swiperData.labels" :key="index">

        <img v-if="slide.image_url" :src="slide.image_url" alt="">
      </swiper-slide>
      <div class="swiper-pagination" style="bottom: 30px" slot="pagination"></div>
    </swiper>
    <div class="button-box left-button" @click="goPre()">
      <i class="iconfont icon-papa-left prediction_icon_button prediction_icon_button-left"
         :class="`prediction_icon_button-left`"></i>
    </div>
    <div class="button-box right-button" @click="goNext()">
      <i class="iconfont icon-papa-right1 prediction_icon_button prediction_icon_button-right"
         :class="`prediction_icon_button-right`"></i>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        currentSlideId: 0,
        swiperOption: {
          slidesPerView: 1,
          autoplay: false,
          loop: true,
          centeredSlides: true,
          effect: 'slide',
          spaceBetween: 30,
          navigation: {}
        }
      }
    },
    props: {
      swiperData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    methods: {
      changeSlider (item, index) {
        this.currentSlideId = index
        this.$refs.swiperrefs.swiper.slideTo(index+1, 300, false)
      },
      goPre () {
        let index = this.currentSlideId - 1
        if (index < 0) {
          index = this.swiperData.labels.length - 1
        }
        this.currentSlideId = index
        this.$refs.swiperrefs.swiper.slidePrev()
      },
      goNext () {
        let index = this.currentSlideId + 1
        if (index > this.swiperData.labels.length - 1) {
          index = 0
        }
        this.currentSlideId = index
        this.$refs.swiperrefs.swiper.slideNext()
      }
    }
  }
</script>
<style lang="less" scoped>
  .swiper-container {
    width: 600px;
    /deep/ .swiper-wrapper{
      display: flex;
      align-items: center;
    }
    /deep/ .swiper-slide {
      width: 100%;
      height: 100%;
      overflow: hidden;

      /deep/ img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }

  .button-paginations {
    display: flex;
    margin: 16px 0px 30px 0;
    overflow-x: auto;
  }

  .pagination-button {
    min-width: 80px;
    height: 42px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    text-align: center;
    line-height: 42px;
    font-size: 14px;
    color: #555;
    margin-right: 10px;
    cursor: pointer;
  }

  .pagination-button-active {
    color: @papaGreen;
    background: #F1F8F4;
    border-color: @papaGreen;
  }

  .button-box {
    position: absolute;
    top: 50%;
    margin-top: 18px;
  }

  .left-button {
    left: 30px;
  }

  .right-button {
    right: 30px;
    cursor: pointer;
  }

  .prediction_icon_button {
    font-size: 40px;
    outline: none;
    color: #ccc;
  }

  .prediction_icon_button:hover {
    font-size: 40px;
    outline: none;
    color: @papaGreen;
  }
</style>
